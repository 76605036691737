import {
  AvailableWidgetId,
  CategoricalTextDistributionInsightElementResponse,
  ColumnKind,
  type DataPoint,
  SegmentKind,
  type SegmentedDataSeriesResponse,
  WidgetKind,
} from '@/api'

export const categoricalTextDistributionMock: CategoricalTextDistributionInsightElementResponse = {
  id: 'ie_CategoricalTextDistribution',
  kind: 'CategoricalTextDistributionInsight',
  section_id: 'sc_m3j39',
  created: '2024-03-06T16:01:52.231Z',
  last_modified: '2024-03-06T16:01:52.231Z',
  order_id: 1,
  categorical_text_treemap: {
    id: 'wg_1',
    insight_element_id: 'ie_ie_CategoricalTextDistribution',
    name: 'Categorical Text treemap',
    kind: WidgetKind.CATEGORICAL_TEXT_DISTRIBUTION_INSIGHT_TREEMAP,
    available_identifier: AvailableWidgetId.CATEGORICAL_TEXT_TREEMAP,
    widget_config: {
      id: 'wg_1',
      widget_id: 'wg_1',
      bucket_configs: [],
    },
    datasources: [
      {
        column_type: ColumnKind.TEXT_TO_ANALYZE,
        widget_id: 'wg_1',
      },
    ],
    quick_selectors: [],
  },
  categorical_text_bar: {
    id: 'wg_2',
    insight_element_id: 'ie_ie_CategoricalTextDistribution',
    name: 'Categorical Text bar',
    kind: WidgetKind.CATEGORICAL_TEXT_DISTRIBUTION_INSIGHT_BAR,
    available_identifier: AvailableWidgetId.CATEGORICAL_TEXT_BAR,
    widget_config: {
      id: 'wg_2',
      widget_id: 'wg_2',
      bucket_configs: [],
    },
    datasources: [
      {
        column_type: ColumnKind.TEXT_TO_ANALYZE,
        widget_id: 'wg_1',
      },
    ],
    quick_selectors: [],
  },
  visible_left: CategoricalTextDistributionInsightElementResponse.visible_left.CATEGORICAL_TEXT_TREEMAP,
  filters: [],
  available_filters: {},
}

const createDataPoint = (): DataPoint => {
  return {
    row_count: Math.random() * 100,
    row_count_relative: Math.random(),
    score: 0,
  }
}

export const mockSegmentsDict: Record<string, { name: string }> = {
  sg_1: {
    name: 'Segment 1',
  },
  sg_2: {
    name: 'Segment 2',
  },
  sg_3: {
    name: 'Segment 3',
  },
}

// TO DO: minimize this
export const getMockUnSegmentedDataSeriesResponse = (
  overallSegmentId: string = 'overall'
): SegmentedDataSeriesResponse => ({
  kind: 'segmented-data-series',
  values: [
    {
      segment_id: overallSegmentId,
      segment_kind: SegmentKind.OVERALL,
      values: Array.from({ length: 10 }, createDataPoint),
    },
  ],
  tick_labels: Array.from({ length: 10 }, (_, i) => `Label ${i}`),
})

// TO DO: minimize this
export const getMockSegmentedDataSeriesResponse = (): SegmentedDataSeriesResponse => ({
  kind: 'segmented-data-series',
  values: [
    {
      segment_id: 'sg_1',
      segment_kind: SegmentKind.USER_DEFINED,
      values: Array.from({ length: 10 }, createDataPoint),
    },
    {
      segment_id: 'sg_2',
      segment_kind: SegmentKind.USER_DEFINED,
      values: Array.from({ length: 10 }, createDataPoint),
    },
    {
      segment_id: 'sg_3',
      segment_kind: SegmentKind.USER_DEFINED,
      values: Array.from({ length: 10 }, createDataPoint),
    },
  ],
  tick_labels: Array.from({ length: 10 }, (_, i) => `Label ${i}`),
})
