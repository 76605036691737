<template>
  <div v-if="showSurvey" id="posthog-survey" class="survey-container max-w-72 shadow-2xl">
    <NButton class="close-icon" quaternary circle icon @click="closeSurvey">
      <FaIcon icon="far fa-times" />
    </NButton>

    <div class="survey-box">
      <div>
        <div>
          <div class="survey-question">{{ $t('posthog.survey_question') }}</div>
          <div class="survey-question-description">{{ $t('posthog.description') }}</div>
        </div>
        <textarea v-model="surveyResponse" rows="4" :placeholder="$t('posthog.input_placeholder')" />
        <div class="bottom-section">
          <div class="buttons">
            <NButton class="w-100%" size="large" type="primary" @click="submitSurvey">
              {{ $t('common.submit') }}
            </NButton>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { submitFeedbackSurvey } from '@/plugins/posthog'
import { useEventSubscription } from '@/composables/useEventBus'

const showSurvey = ref(false)
const renderedSurveyId = ref<string>()
const surveyResponse = ref<string>()

useEventSubscription('render-survey', (surveyId) => {
  showSurvey.value = true
  renderedSurveyId.value = surveyId
})

const submitSurvey = () => {
  if (renderedSurveyId.value && surveyResponse.value) {
    submitFeedbackSurvey(renderedSurveyId.value, surveyResponse.value)
    closeSurvey()
    window.$message.success('Thank you for your feedback!')
  }
}

const closeSurvey = () => {
  showSurvey.value = false
  surveyResponse.value = ''
  renderedSurveyId.value = undefined
}
</script>

<style lang="scss" scoped>
.survey-container {
  position: absolute;
  bottom: 12px;
  right: 12px;
  background-color: var(--c-base);
  border-radius: 12px;
  z-index: 1000;
  padding: 20px 25px 10px;

  :deep(.survey-box > div) {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .survey-question {
      font-size: 16px;
      font-weight: 600;
      padding-right: 10px;
    }

    .survey-question-description {
      font-size: 14px;
      margin-top: 4px;
    }

    & > textarea {
      min-height: 100px;
      font-size: 14px;
      background: var(--card-color);
      outline: none;
      padding-left: 10px;
      padding-right: 10px;
      padding-top: 10px;
      border-radius: 6px;
      border-color: #dfd8cf;
      width: 100%;
      box-sizing: border-box;
    }

    .footer-branding {
      display: none;
    }
  }

  .close-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 20px;
    background: none;
    border: none;
    cursor: pointer;
    color: var(--c-text-light);
    padding: 0;
    line-height: 1;
    z-index: 1001;
  }
}
</style>
