/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type CodeFilter = {
  invert?: boolean;
  value: (Array<number> | null);
  type?: CodeFilter.type;
};
export namespace CodeFilter {
  export enum type {
    CODES = 'codes',
  }
}

