/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AnonymizePIIResponse } from './AnonymizePIIResponse';
import type { AuxiliaryFieldUIResponse } from './AuxiliaryFieldUIResponse';
import type { DataSourceUIResponse } from './DataSourceUIResponse';
import type { TextToAnalyzeFieldUIResponse } from './TextToAnalyzeFieldUIResponse';
export type ProjectDetailUIResponse = {
  id: string;
  name: string;
  description: string;
  owner_name: string;
  owner_email: string;
  owner_id: string;
  labels: Array<string>;
  completed: boolean;
  upload_status: ProjectDetailUIResponse.upload_status;
  language: ProjectDetailUIResponse.language;
  translation_status?: ProjectDetailUIResponse.translation_status;
  translation_engine?: ProjectDetailUIResponse.translation_engine;
  data_source_provider: ProjectDetailUIResponse.data_source_provider;
  data_source?: DataSourceUIResponse;
  rows_count: number;
  created: string;
  last_modified: string;
  /**
   * [DEPRECATED - use all_permissions instead] Contains only object permissions (not considering role/owner/org root)
   */
  permissions: Record<string, boolean>;
  /**
   * Contains all object permissions based on org root, owner, role, object
   */
  all_permissions: Array<string>;
  columns: Array<(TextToAnalyzeFieldUIResponse | AuxiliaryFieldUIResponse)>;
  report_case_limit?: number;
  anonymize_pii?: AnonymizePIIResponse;
  date_column?: string;
  score_column?: string;
};
export namespace ProjectDetailUIResponse {
  export enum upload_status {
    PENDING = 'pending',
    IN_PROGRESS = 'in_progress',
    SUCCEEDED = 'succeeded',
    FAILED = 'failed',
  }
  export enum language {
    AF = 'af',
    SQ = 'sq',
    EU = 'eu',
    CA = 'ca',
    CS = 'cs',
    DA = 'da',
    NL = 'nl',
    EN = 'en',
    ET = 'et',
    FI = 'fi',
    FR = 'fr',
    GL = 'gl',
    DE = 'de',
    EL = 'el',
    HU = 'hu',
    IS = 'is',
    IT = 'it',
    LB = 'lb',
    LT = 'lt',
    LV = 'lv',
    MK = 'mk',
    NO = 'no',
    PL = 'pl',
    PT = 'pt',
    RO = 'ro',
    SR = 'sr',
    SK = 'sk',
    SL = 'sl',
    ES = 'es',
    SV = 'sv',
    TR = 'tr',
  }
  export enum translation_status {
    PENDING = 'pending',
    SUCCEEDED = 'succeeded',
    FAILED = 'failed',
  }
  export enum translation_engine {
    GOOGLE_TRANSLATE = 'google_translate',
    DEEPL = 'deepl',
  }
  export enum data_source_provider {
    QUALTRICS = 'qualtrics',
    GOOGLE_PLACES = 'google_places',
    TRUSTPILOT = 'trustpilot',
    APPLE_APPSTORE = 'apple_appstore',
    GOOGLE_PLAYSTORE = 'google_playstore',
    AMAZON = 'amazon',
    G2 = 'g2',
    CAPTERRA = 'capterra',
    YELP = 'yelp',
    UPLOAD = 'upload',
    BRANDWATCH = 'brandwatch',
  }
}

