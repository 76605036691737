import {
  AvailableWidgetId,
  ColumnKind,
  type NestedBucketCategoryTopicsValues,
  type NestedCategoryTopicBucketCount,
  type NpsScoreBreakdownInsightElementResponse,
} from '@/api'
import { randomColor, randomRange } from '@/utils/random'

export const npsScoreBreakdownMock: NpsScoreBreakdownInsightElementResponse = {
  id: 'ie_546452',
  kind: 'NPSScoreBreakdown',
  section_id: 'sc_m3j3xcasd',
  created: '2024-03-06T16:01:52.231Z',
  last_modified: '2024-03-06T16:01:52.231Z',
  order_id: 1,
  nps_score_breakdown: {
    id: 'npsscore_breakdown_wg_1',
    insight_element_id: 'ie_546452',
    name: 'NPS Score Breakdown',
    kind: 'TopicsOverview_SegmentedBucketBarChart',
    available_identifier: AvailableWidgetId.NPS_SCORE_BREAKDOWN,
    widget_config: {
      id: 'wg_1',
      widget_id: 'wg_1',
      bucket_configs: [],
    },
    datasources: [
      {
        column_type: ColumnKind.TEXT_TO_ANALYZE,
        widget_id: 'wg_1',
      },
    ],
    quick_selectors: [],
  },
  filters: [],
  available_filters: {},
}

const dummyTopicList = ['Satisfaction', 'Recommendation', 'Service', 'Website', 'Pricing', 'Loyalty']
const dummyCategoryList = ['COSR', 'MISC', 'CUSTOMER SERVICE', 'BRAND']

export const MOCK_SEGMENT_ID = 'MOCK_SEGMENT_ID'

export const nestedBarChartMock: NestedBucketCategoryTopicsValues = {
  kind: 'sentiment-category-topics-segment-bucket-data-points',
  results: {
    any: Array.from(Array(4)).map<NestedCategoryTopicBucketCount>((_, index) => ({
      id: `any_${index}`,
      label: dummyCategoryList[index],
      values: {
        detractors: {
          row_count: randomRange(0, 100),
          row_count_relative: randomRange(0, 100),
          score: randomRange(0, 100),
        },
        passives: {
          row_count: randomRange(0, 100),
          row_count_relative: randomRange(0, 100),
          score: randomRange(0, 100),
        },
        promoters: {
          row_count: randomRange(0, 100),
          row_count_relative: randomRange(0, 100),
          score: randomRange(0, 100),
        },
      },
      topics: dummyTopicList.map((topic) => ({
        id: topic,
        label: topic,
        values: {
          detractors: {
            row_count: randomRange(0, 100),
            row_count_relative: randomRange(0, 100),
            score: randomRange(0, 100),
          },
          passives: {
            row_count: randomRange(0, 100),
            row_count_relative: randomRange(0, 100),
            score: randomRange(0, 100),
          },
          promoters: {
            row_count: randomRange(0, 100),
            row_count_relative: randomRange(0, 100),
            score: randomRange(0, 100),
          },
        },
        color: randomColor(),
      })),
    })),
  },
}
