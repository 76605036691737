<template>
  <div class="c-login-form">
    <NFormItem v-if="!otpInput" class="relative" :path="formPath" v-bind="formItemProps" :show-label="false">
      <div class="w-full">
        <label class="font-500 c-gray-600 text-sm" v-html="placeholder" />
        <NInput
          ref="inputRef"
          :type="type"
          size="large"
          :value="modelValue"
          :input-props="inputProps"
          class="mt-1"
          @input="(v: string) => $emit('update:modelValue', v)"
          @keydown.enter="$emit('on:next')"
          @blur="$emit('blur')"
        />
        <div v-if="newPassword" class="mt-3">
          <label class="font-500 c-gray-600 text-sm">Repeat password</label>
          <NInput v-model:value="repeatPassword" :type="type" size="large" class="mt-1" />
        </div>
      </div>
    </NFormItem>
    <div v-else class="flex flex-col items-center text-center">
      <div class="c-header-text-color text-size-base mb-5">{{ placeholder }}</div>
      <VOtpInput
        :value="modelValue"
        input-classes="otp-input"
        separator=""
        input-type="number"
        :num-inputs="6"
        :should-auto-focus="true"
        :should-focus-order="true"
        @keydown.enter="$emit('on:next')"
        @on-change="(v: string) => $emit('update:modelValue', v)"
        @on-complete="(v: string) => $emit('update:modelValue', v)"
      />
    </div>
    <slot />

    <!-- Forgot password -->
    <NSpace v-if="(!step || step === LoginResponse.next_step.PASSWORD) && !newPassword" justify="end" class="mt-1">
      <NButton text :disabled="loading" type="primary" @click="$emit('on:reset')">
        {{ reset ? $t('login_view.back_to_login') : $t('login_view.forgot_password') }}
      </NButton>
    </NSpace>
    <br />
    <NButton
      class="w-full"
      size="large"
      type="primary"
      :loading="loading"
      :disabled="!modelValue.length || (newPassword && modelValue !== repeatPassword)"
      @click="$emit('on:next')"
    >
      {{
        reset ? $t('login_view.reset_password') : newPassword ? $t('login_view.save_password') : $t('common.continue')
      }}
    </NButton>
  </div>
</template>

<script setup lang="ts">
import VOtpInput from 'vue3-otp-input'
import { LoginResponse } from '@/api'
import { onMounted, ref } from 'vue'
import type { FormItemProps, InputInst, InputProps } from 'naive-ui'

interface ILoginStepProps {
  modelValue?: string
  type?: 'text' | 'password' | 'textarea' | undefined
  placeholder?: string
  formPath?: string
  inputProps?: InputProps['inputProps']
  step?: string
  loading?: boolean
  reset?: boolean
  formItemProps?: FormItemProps
  newPassword?: boolean
  otpInput?: boolean
}

withDefaults(defineProps<ILoginStepProps>(), {
  modelValue: '',
  type: 'text',
  placeholder: '',
  step: '',
  reset: false,
})

defineEmits<{
  'update:modelValue': [value: string]
  'on:next': []
  'on:reset': []
  blur: []
}>()

const inputRef = ref<InputInst>()

const repeatPassword = ref('')

onMounted(() => {
  inputRef.value?.focus()
})
</script>

<style lang="scss" scoped>
.c-login-form {
  :deep(.n-form-item-feedback-wrapper) {
    position: absolute;
    bottom: 0;
    transform: translateY(100%);
  }

  :deep(.otp-input) {
    width: 40px;
    height: 40px;
    margin: 0 4px;
    font-size: 16px;
    border-radius: 8px;
    border: 1px solid var(--divider-color);
    text-align: center;
    box-sizing: border-box;

    ::-webkit-inner-spin-button,
    ::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
}
</style>
