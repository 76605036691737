<template>
  <NSpin :show="loading || loadingValues">
    <NSlider
      v-model:value="internalValue"
      range
      class="min-w-[300px]"
      :max="internalMax"
      :min="internalMin"
      @update:value="handleInputUpdate"
    />
    <div class="mt-4 flex gap-4">
      <div>
        <NInputNumber
          v-model:value="internalValue[0]"
          size="small"
          :min="internalMin"
          :max="internalValue ? internalValue[1] : internalMax"
          @update:value="
            (val) =>
              handleInputUpdate([isNumber(val) ? val : internalMin, internalValue ? internalValue[1] : internalMax])
          "
        />
      </div>
      <div>
        <NInputNumber
          v-model:value="internalValue[1]"
          size="small"
          :min="internalValue ? internalValue[0] : internalMin"
          :max="internalMax"
          @update:value="
            (val) =>
              handleInputUpdate([internalValue ? internalValue[0] : internalMin, isNumber(val) ? val : internalMax])
          "
        />
      </div>
    </div>
  </NSpin>
</template>

<script setup lang="ts">
import { isNumber } from 'lodash-es'
import { onMounted, ref, watch } from 'vue'
import { useDebounceFn } from '@vueuse/core'

interface IRangeSliderProps {
  lazy?: boolean
  fetchValues?: () => Promise<[number, number] | undefined>
  value?: Array<number>
  min?: number
  max?: number
  loading?: boolean
  debounced?: boolean
}

const props = withDefaults(defineProps<IRangeSliderProps>(), {
  debounced: true,
})

const emit = defineEmits<{
  'update:value': [value: number[]]
}>()

const loadingValues = ref(false)
const internalMin = ref<number>(props.min || 0)
const internalMax = ref<number>(props.max || 0)
const internalValue = ref<number[]>([props.min || 0, props.max || 0])

onMounted(() => {
  if (props.lazy) {
    loadingValues.value = true
    props.fetchValues?.().then((res) => {
      if (!res) return

      const [min, max] = res

      internalMin.value = min
      internalMax.value = max
      internalValue.value = [min, max]
      loadingValues.value = false
    })
  }
})

watch(
  () => props.min,
  (newValue) => {
    if (!newValue) return
    internalMin.value = newValue
    if (internalValue.value[0] < newValue) internalValue.value[0] = newValue
  },
  {
    immediate: true,
  }
)

watch(
  () => props.max,
  (newValue) => {
    if (!newValue) return
    internalMax.value = newValue
    if (internalValue.value[1] > newValue) internalValue.value[1] = newValue
  },
  {
    immediate: true,
  }
)

watch(
  () => props.value,
  (newValue) => {
    // bind inner value to the prop value
    if (newValue) internalValue.value = newValue
  },
  {
    immediate: true,
  }
)

const debouncedUpdateValue = useDebounceFn(
  (value: number[]) => {
    // update prop with a debounced function
    emit('update:value', value)
  },
  props.debounced ? 400 : 0
)

const handleInputUpdate = (value: number[]) => {
  // update inner value immediately not to loose reactivity
  internalValue.value = value || []
  debouncedUpdateValue(value)
}
</script>

<style lang="scss" scoped></style>
