/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * Defines the kind of the InsightElement as exposed to the client (e.g. FE) code
 */
export enum InsightElementKind {
  REPORT_SUMMARY_INSIGHT = 'ReportSummaryInsight',
  REPORT_SUMMARY_NPSWO_TIME_INSIGHT = 'ReportSummaryNPSWoTimeInsight',
  REPORT_SUMMARY_REVIEWS_INSIGHT = 'ReportSummaryReviewsInsight',
  REPORT_SUMMARY_REVIEWS_WO_TIME_INSIGHT = 'ReportSummaryReviewsWoTimeInsight',
  NPSSCORE_INSIGHT = 'NPSScoreInsight',
  NPSSCORE_WO_TIME_INSIGHT = 'NPSScoreWoTimeInsight',
  NPSSCORE_BREAKDOWN = 'NPSScoreBreakdown',
  OVERALL_SENTIMENT_INSIGHT = 'OverallSentimentInsight',
  STAR_RATING_INSIGHT = 'StarRatingInsight',
  STAR_RATING_WO_TIME_INSIGHT = 'StarRatingWoTimeInsight',
  TOPICS_OVERVIEW_INSIGHT = 'TopicsOverviewInsight',
  TOPIC_TRENDS_POSITIVE_TABLE_INSIGHT = 'TopicTrendsPositiveTableInsight',
  TOPIC_TRENDS_NEGATIVE_TABLE_INSIGHT = 'TopicTrendsNegativeTableInsight',
  TOPIC_TRENDS_IN_DEPTH_INSIGHT = 'TopicTrendsInDepthInsight',
  DRIVER_OVERVIEW_INSIGHT = 'DriverOverviewInsight',
  TOPIC_SENTIMENT_INSIGHT = 'TopicSentimentInsight',
  SELECTED_ROWS_INSIGHT = 'SelectedRowsInsight',
  CATEGORICAL_TEXT_DISTRIBUTION_INSIGHT = 'CategoricalTextDistributionInsight',
  MARKDOWN_TEXT_INSIGHT = 'MarkdownTextInsight',
  TOPIC_BREAKOUT_INSIGHT = 'TopicBreakoutInsight',
  NUMERICAL_DISTRIBUTION_INSIGHT = 'NumericalDistributionInsight',
}
