<template>
  <div class="bg-card-color flex h-full w-full">
    <div class="lg:w-40% w-full">
      <div class="ml-auto mr-auto flex h-full w-[345px] flex-col items-center justify-center">
        <NImage :src="logoURL" preview-disabled />

        <!-- check e-mail screen -->
        <div v-if="checkEmailScreen" class="text-center">
          <h2 class="mt-4! text-center">
            {{ $t('login_view.reset_success') }}
          </h2>
          <NButton class="font-600" text @click="backToLoginStep">
            {{ $t('login_view.back_to_login') }}
          </NButton>
        </div>

        <template v-else>
          <h2
            class="mt-4! text-center"
            v-html="isPasswordReset ? $t('login_view.set_new_password') : $t('login_view.login_title')"
          />

          <NForm class="w-full">
            <!-- Email -->
            <LoginStep
              v-if="!currentStep && !isPasswordReset"
              v-model="email"
              form-path="email"
              :placeholder="$t('login_view.email_placeholder')"
              :step="currentStep"
              :loading="loading"
              :form-item-props="emailAttrs.fieldAttrs"
              :reset="passwordReset"
              v-on="emailAttrs.inputListeners"
              @on:next="passwordReset ? reset() : login(false, 'email')"
              @on:reset="passwordReset = !passwordReset"
            />

            <!-- Password -->
            <LoginStep
              v-else-if="currentStep === LoginResponse.next_step.PASSWORD || isPasswordReset"
              v-model="password"
              type="password"
              form-path="password"
              :placeholder="isPasswordReset ? $t('login_view.new_password') : $t('login_view.password', { email })"
              :new-password="isPasswordReset"
              :step="currentStep"
              :loading="loading"
              :form-item-props="passwordAttrs.fieldAttrs"
              v-on="passwordAttrs.inputListeners"
              @on:next="isPasswordReset ? setNewPassword() : login(false, 'password')"
              @on:reset="reset"
            />

            <!-- Device select mode   -->
            <div v-else-if="deviceSelectMode" class="flex flex-col text-center">
              <div class="c-header-text-color text-size-base mb-5">{{ $t('login_view.choose_device') }}</div>
              <NButton
                v-for="device in availableDevices"
                :key="device.id"
                type="primary"
                class="mt-2 flex"
                :disabled="loading"
                size="large"
                @click="() => select2FADevice(device)"
              >
                {{ $t(`settings.security.device_types.${device.type}`) }}
              </NButton>
            </div>

            <!-- Auth Code -->
            <LoginStep
              v-else-if="
                currentStep === LoginResponse.next_step._2FA_DEVICE ||
                currentStep === LoginResponse.next_step._2FA_TOKEN
              "
              v-model="authCode"
              form-path="authCode"
              :otp-input="selectedDevice?.type !== 'backup_codes'"
              :placeholder="authCodePlaceholder"
              :step="currentStep"
              :loading="loading"
              :input-props="{
                name: 'one-time-code',
                inputmode: 'numeric',
              }"
              :form-item-props="authCodeAttrs.fieldAttrs"
              v-on="authCodeAttrs.inputListeners"
              @on:next="login(false, 'authCode')"
            />
          </NForm>
          <div
            v-if="
              currentStep === LoginResponse.next_step._2FA_DEVICE || currentStep === LoginResponse.next_step._2FA_TOKEN
            "
          >
            <!-- 2-f-a device select -->
            <div
              v-if="availableDevices && availableDevices.length && availableDevices.length > 1"
              class="mt-4 text-center"
            >
              <div v-if="!deviceSelectMode">
                {{ $t('login_view.more_available') }}
                <NButton type="primary" text class="mt-1" @click="deviceSelectMode = true">
                  {{ $t('settings.security.show_devices') }}
                </NButton>
              </div>
            </div>
          </div>
          <div v-else-if="!urlParams.setpwtoken" class="mt-4 text-center">
            <div>
              {{ $t('login_view.new_to_caplena') }}
              <a :href="demoFormUrl" class="c-gray-700 font-600">{{ $t('login_view.book') }}</a>
            </div>
            <NDivider />
            <div class="text-xs">
              <span v-html="$t('login_view.trouble')" />
              <a class="c-gray-700 font-500" href="mailto:support@caplena.com">support@caplena.com</a>
            </div>
          </div>
        </template>
      </div>
    </div>
    <div class="lg:w-60% hidden lg:block">
      <div class="box-border flex h-full items-center justify-center p-1">
        <NCard
          :bordered="false"
          content-class="p-0!"
          :style="{ height: 'calc(100vh - 8px)', backgroundColor: 'rgba(208, 244, 255, 1)' }"
          :content-style="`border-radius: 16px;`"
        >
          <Vue3Lottie :animation-data="LoginAnimationJSON" :loop="false" :style="{ height: 'calc(100vh - 8px)' }" />
        </NCard>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import LoginAnimationJSON from '@/assets/animations/login.json'
import LoginStep from './components/LoginStep.vue'
import logoURL from '@/assets/img/logo-black-text.svg'
import { ApiError, DeviceResponse, LoginResponse, getErrorMsg } from '@/api'
import { Vue3Lottie } from 'vue3-lottie'
import { authCodeRule, emailRule, passwordRule, useValidatedForm } from '@/plugins/form-validation'
import { computed, nextTick, onMounted, ref } from 'vue'
import { object } from 'yup'
import { useAuthStore } from '@/store'
import { useMessage } from 'naive-ui'
import { useRouter } from 'vue-router'
import { useTranslate } from '@tolgee/vue'
import { useUrlSearchParams } from '@vueuse/core'

const formSchema = object({
  email: emailRule(),
  password: passwordRule(),
  authCode: authCodeRule(),
})

const {
  isFormValid,
  isFieldValid,
  formFields: {
    email: [email, emailAttrs],
    password: [password, passwordAttrs],
    authCode: [authCode, authCodeAttrs],
  },
} = useValidatedForm(formSchema, {
  authCode: '',
  email: '',
  password: '',
})

const auth = useAuthStore()
const router = useRouter()
const { t } = useTranslate()
const message = useMessage()
const deviceSelectMode = ref(false)
const loading = ref(false)
const currentStep = ref()
const selectedDevice = ref<DeviceResponse>()
const availableDevices = ref<DeviceResponse[]>([])
const passwordReset = ref(false)
const urlParams = useUrlSearchParams('history')
const demoFormUrl = import.meta.env.CAPP_LANDING_PAGE_URL
const checkEmailScreen = ref(false)

const isPasswordReset = computed(() => !!router.currentRoute.value.query.setpwtoken)

const authCodePlaceholder = computed(() => {
  return t.value(`settings.security.code_select`, {
    device: t.value(`settings.security.device_types.${selectedDevice.value?.type}`),
  })
})

const throwError = (msg: string | string[] = 'Something went wrong during login') => {
  if (Array.isArray(msg)) {
    msg.forEach((m) => {
      message.error(m)
      console.error(m)
    })
  } else if (typeof msg === 'string') {
    message.error(msg)
    console.error(msg)
  }
}

const redirect = (path: string | undefined = undefined) => {
  // On production enviroment redirect to old app
  // LEGACY REDIRECT IN PRODUCTION (TO BE REMOVED WHEN OLD APP IS REMOVED)
  if (import.meta.env.PROD && import.meta.env.MODE === 'production') {
    // no redirection
    if (!urlParams.go_to) {
      console.log('redirect to: https://caplena.com/app')
      window.location.href = 'https://caplena.com/app'

      return
    }

    if (!urlParams.go_to.includes('/app/')) {
      console.log('no redirection needed')
      // this is a redirect for the new app
      router.push(urlParams.go_to as string)
      return
    }

    console.log(`redirect to: https://caplena.com${urlParams.go_to}`)
    // we have a go_to param to old app -> https://caplena.com/app/whatever-url
    window.location.href = `https://caplena.com${urlParams.go_to}`
    return
  }

  // custom url param
  if (path) router.push(path)

  // no custom url && has redirectURL
  if (urlParams.go_to) {
    router.push(urlParams.go_to as string)
    return
  }

  // default: redirect home
  router.push('/')
}

const reset = async () => {
  if (!isFieldValid('email')) return

  loading.value = true

  const res = await auth.reset({ email: email.value })

  loading.value = false

  if (res?.error) {
    throwError(getErrorMsg(res?.error as ApiError))
    return
  }

  passwordReset.value = false
  checkEmailScreen.value = true
}

const setNewPassword = async () => {
  const res = await auth.userSetPassword(urlParams.setpwtoken as string, { password: password.value })

  if (res?.error) {
    throwError(getErrorMsg(res?.error as ApiError))
    return
  }

  password.value = ''

  message.success(t.value('login_view.password_set_success'))

  router.push({ path: '/login', query: {} })
}

const verifyEmail = async () => {
  const res = await auth.verifyEmail(urlParams.activationkey as string)

  if (res && 'saml2_redirect' in res) {
    // For SSO accounts, directly go to IDP login page
    window.location.href = res.saml2_redirect
    return
  }

  if (res?.error) {
    throwError(getErrorMsg(res?.error as ApiError))
    return
  }

  message.success(t.value('login_view.verify_success'))
}

const login = async (skipFormValidation = false, field?: 'authCode' | 'email' | 'password') => {
  if (!skipFormValidation) {
    if (field && !isFieldValid(field)) return
    else if (!field && !isFormValid.value) return
  }

  loading.value = true

  const res = await auth.login({
    email: email.value,
    password: password.value,
    token: authCode.value,
    device_id: selectedDevice.value?.id,
  })

  if (!res || res.error) {
    loading.value = false
    throwError(getErrorMsg(res?.error as ApiError))
    return
  }

  // login successful - no 2FA configured
  if (res.nextStep === LoginResponse.next_step.DONE) {
    await auth.getUser()
    redirect()
    return
  }

  // configure UI for 2FA challenge
  if (res.nextStep === LoginResponse.next_step._2FA_DEVICE && res.devices && res.devices.length) {
    // Select default device
    availableDevices.value = res.devices

    const preferredTypes = ['app', 'sms', 'backup_codes']
    // Find the first matching device from the preferred types
    const defaultDevice = preferredTypes.reduce<DeviceResponse | undefined>(
      (result, type) => result || res.devices?.find((device) => device.type === type),
      undefined
    )

    if (defaultDevice) select2FADevice(defaultDevice)
  }

  // configure UI for 2FA challenge
  if (res.nextStep === LoginResponse.next_step._2FA_TOKEN) {
    selectedDevice.value = res.device
  }

  currentStep.value = res.nextStep
  loading.value = false
}

const select2FADevice = (device: DeviceResponse) => {
  selectedDevice.value = device
  authCode.value = ''
  nextTick(() => login(true))
  deviceSelectMode.value = false
}

const backToLoginStep = () => {
  currentStep.value = undefined
  checkEmailScreen.value = false
  password.value = ''
}

onMounted(() => {
  if (urlParams.activationkey) verifyEmail()
  if (auth.isLoggedIn) redirect()
})
</script>
