import {
  AvailableWidgetId,
  ColumnKind,
  type NestedCategorySegmentTopicCount,
  type NestedSentimentCategoryTopicsSegmentValues,
  type TopicSentimentInsightElementResponse,
  WidgetKind,
} from '@/api'
import { randomColor, randomRange } from '@/utils/random'

export const topicSentimentMock: TopicSentimentInsightElementResponse = {
  id: 'ie_12452',
  kind: 'TopicSentimentInsight',
  section_id: 'sc_m3j39',
  created: '2024-03-06T16:01:52.231Z',
  last_modified: '2024-03-06T16:01:52.231Z',
  order_id: 1,
  sentiment_per_topic: {
    id: 'sentiment_per_topic_wg_1',
    insight_element_id: 'ie_12452',
    name: 'Sentiment per topic',
    kind: WidgetKind.TOPIC_SENTIMENT_STACKED_BAR_CHART,
    available_identifier: AvailableWidgetId.SENTIMENT_PER_TOPIC,
    quick_selectors: [],
    widget_config: {
      id: 'wg_1',
      widget_id: 'wg_1',
      bucket_configs: [],
    },
    datasources: [
      {
        column_type: ColumnKind.TEXT_TO_ANALYZE,
        widget_id: 'wg_1',
      },
    ],
  },
  filters: [],
  available_filters: {},
}

const dummyTopicList = ['Satisfaction', 'Recommendation', 'Service', 'Website', 'Pricing', 'Loyalty']
const dummyCategoryList = ['COSR', 'MISC', 'CUSTOMER SERVICE', 'BRAND']

export const MOCK_SEGMENT_ID = 'MOCK_SEGMENT_ID'

export const nestedBarChartMock: NestedSentimentCategoryTopicsSegmentValues = {
  kind: 'sentiment-category-topics-segment-values',
  results: Array.from(Array(10)).reduce<Record<string, Array<NestedCategorySegmentTopicCount>>>(
    (acc) => ({
      ...acc,
      any: dummyCategoryList.map((category) => ({
        id: category,
        label: category,
        values: {
          [MOCK_SEGMENT_ID]: {
            values: {
              positive: {
                value: randomRange(0, 100),
                value_relative: randomRange(0, 100),
              },
              negative: {
                value: randomRange(0, 100),
                value_relative: randomRange(0, 100),
              },
              neutral: {
                value: randomRange(0, 100),
                value_relative: randomRange(0, 100),
              },
            },
          },
        },
        topics: dummyTopicList.map((topic) => ({
          id: topic,
          label: topic,
          values: {
            [MOCK_SEGMENT_ID]: {
              values: {
                positive: {
                  value: randomRange(0, 100),
                  value_relative: randomRange(0, 100),
                },
                negative: {
                  value: randomRange(0, 100),
                  value_relative: randomRange(0, 100),
                },
                neutral: {
                  value: randomRange(0, 100),
                  value_relative: randomRange(0, 100),
                },
              },
            },
          },
          color: randomColor(),
        })),
      })),
      neutral: dummyCategoryList.map((category) => ({
        id: category,
        label: category,
        values: {
          [MOCK_SEGMENT_ID]: {
            values: {
              neutral: {
                value: randomRange(0, 100),
                value_relative: randomRange(0, 100),
              },
            },
          },
        },
        topics: dummyTopicList.map((topic) => ({
          id: topic,
          label: topic,
          values: {
            [MOCK_SEGMENT_ID]: {
              values: {
                neutral: {
                  value: randomRange(0, 100),
                  value_relative: randomRange(0, 100),
                },
              },
            },
          },
          color: randomColor(),
        })),
      })),
      positive: dummyCategoryList.map((category) => ({
        id: category,
        label: category,
        values: {
          [MOCK_SEGMENT_ID]: {
            values: {
              positive: {
                value: randomRange(0, 100),
                value_relative: randomRange(0, 100),
              },
            },
          },
        },
        topics: dummyTopicList.map((topic) => ({
          id: topic,
          label: topic,
          values: {
            [MOCK_SEGMENT_ID]: {
              values: {
                positive: {
                  value: randomRange(0, 100),
                  value_relative: randomRange(0, 100),
                },
              },
            },
          },
          color: randomColor(),
        })),
      })),
      negative: dummyCategoryList.map((category) => ({
        id: category,
        label: category,
        values: {
          [MOCK_SEGMENT_ID]: {
            values: {
              negative: {
                value: randomRange(0, 100),
                value_relative: randomRange(0, 100),
              },
            },
          },
        },
        topics: dummyTopicList.map((topic) => ({
          id: topic,
          label: topic,
          values: {
            [MOCK_SEGMENT_ID]: {
              values: {
                negative: {
                  value: randomRange(0, 100),
                  value_relative: randomRange(0, 100),
                },
              },
            },
          },
          color: randomColor(),
        })),
      })),
    }),
    {}
  ),
}
