import {
  AvailableWidgetId,
  ColumnKind,
  NpsScoreWoTimeInsightElementResponse,
  SegmentKind,
  type SegmentedDataSeriesResponse,
  type SegmentedDataSeriesWithOverallResponse,
  WidgetKind,
} from '@/api'

export const npsInsightWoTimeMock: NpsScoreWoTimeInsightElementResponse = {
  id: 'ie_1',
  kind: 'NPSScoreWoTimeInsight',
  section_id: 'sc_m3j39',
  created: '2024-03-06T16:01:52.231Z',
  last_modified: '2024-03-06T16:01:52.231Z',
  filters: [],
  available_filters: {},
  order_id: 1,
  nps_score_gauge: {
    id: 'wg_1',
    kind: WidgetKind.NPSSCORE_GAUGE_CHART,
    name: 'NPS Score Gauge',
    insight_element_id: 'ie_1',
    available_identifier: AvailableWidgetId.NPS_SCORE_GAUGE,
    widget_config: {
      id: 'wg_1',
      widget_id: 'wg_1',
      bucket_configs: [],
    },
    datasources: [
      {
        column_type: ColumnKind.NUMERICAL,
        widget_id: 'wg_1',
      },
    ],
    quick_selectors: [],
  },
  nps_score_pie: {
    id: 'wg_2',
    kind: WidgetKind.NPSSCORE_PIE_CHART,
    name: 'NPS Score Pie',
    insight_element_id: 'ie_1',
    available_identifier: AvailableWidgetId.NPS_SCORE_PIE,
    widget_config: {
      id: 'wg_2',
      widget_id: 'wg_2',
      bucket_configs: [],
    },
    datasources: [
      {
        column_type: ColumnKind.NUMERICAL,
        widget_id: 'wg_1',
      },
    ],
    quick_selectors: [],
  },
  nps_score_segmented_bar: {
    id: 'wg_3',
    kind: WidgetKind.NPSSCORE_SEGMENTED_BAR_CHART,
    name: 'NPS Segmented Bar',
    insight_element_id: 'ie_1',
    available_identifier: AvailableWidgetId.NPS_SCORE_SEGMENTED_BAR,
    widget_config: {
      id: 'wg_3',
      widget_id: 'wg_3',
      bucket_configs: [],
    },
    datasources: [
      {
        column_type: ColumnKind.NUMERICAL,
        widget_id: 'wg_1',
      },
    ],
    quick_selectors: [],
  },
  detractors_rows: {
    id: 'wg_4',
    kind: WidgetKind.TTA_COLUMN_ROWS,
    datasources: [],
    insight_element_id: 'ie_1',
    name: 'Detractors Rows',
    available_identifier: AvailableWidgetId.DETRACTORS_ROWS,
    quick_selectors: [],
    widget_config: {
      bucket_configs: [],
      id: 'wc_4',
      widget_id: 'wg_4',
    },
  },
  detractors_summary: {
    id: 'wg_5',
    kind: WidgetKind.TEXT,
    datasources: [],
    insight_element_id: 'ie_1',
    name: 'Detractors Summary',
    available_identifier: AvailableWidgetId.DETRACTORS_SUMMARY,
    quick_selectors: [],
    widget_config: {
      bucket_configs: [],
      id: 'wc_5',
      widget_id: 'wg_5',
    },
  },
  promoters_rows: {
    id: 'wg_6',
    kind: WidgetKind.TTA_COLUMN_ROWS,
    datasources: [],
    insight_element_id: 'ie_1',
    name: 'Promoters Rows',
    available_identifier: AvailableWidgetId.PROMOTERS_ROWS,
    quick_selectors: [],
    widget_config: {
      bucket_configs: [],
      id: 'wc_6',
      widget_id: 'wg_6',
    },
  },
  promoters_summary: {
    id: 'wg_7',
    kind: WidgetKind.TEXT,
    datasources: [],
    insight_element_id: 'ie_1',
    name: 'Promoters Summary',
    available_identifier: AvailableWidgetId.PROMOTERS_SUMMARY,
    quick_selectors: [],
    widget_config: {
      bucket_configs: [],
      id: 'wc_7',
      widget_id: 'wg_7',
    },
  },

  visible_left: NpsScoreWoTimeInsightElementResponse.visible_left.NPS_SCORE_GAUGE,
  visible_center: NpsScoreWoTimeInsightElementResponse.visible_center.PROMOTERS_SUMMARY,
  visible_right: NpsScoreWoTimeInsightElementResponse.visible_right.DETRACTORS_ROWS,
}

export const scoreWithScaleMock: SegmentedDataSeriesWithOverallResponse = {
  kind: 'segmented-data-series-with-overall',
  label: 'NPS Score',
  value: 0.6,
  tick_labels: ['detractors', 'passives', 'promoters'],
  values: [
    {
      segment_id: 'sg_1',
      segment_kind: SegmentKind.OVERALL,
      values: [
        {
          score: 20,
          row_count: 30.0,
          row_count_relative: 0.3,
        },
        {
          score: 50,
          row_count: 50.0,
          row_count_relative: 0.5,
        },
        {
          score: 60,
          row_count: 100.0,
          row_count_relative: 0.9,
        },
      ],
    },
  ],
}

export const timeSeriesMock: SegmentedDataSeriesResponse = {
  kind: 'segmented-data-series',
  tick_labels: [
    'Q1 2021',
    'Q2 2021',
    'Q3 2021',
    'Q4 2021',
    'Q1 2022',
    'Q2 2022',
    'Q3 2022',
    'Q4 2022',
    'Q1 2023',
    'Now',
  ],
  values: [
    {
      segment_id: 'sg_er50o52',
      segment_kind: SegmentKind.OVERALL,
      // values: [-25.0, -15.0, 17.0, 11.0, 22.0, 31.0, 1.0, -13.0, -15.0, 1.0, 8.0],
      values: [
        {
          score: 0.4,
          row_count: 25.0,
          row_count_relative: 0.25,
        },
        {
          score: 0.4,
          row_count: 15.0,
          row_count_relative: 0.15,
        },
        {
          score: 0.3,
          row_count: 11.0,
          row_count_relative: 0.11,
        },
        {
          score: 0.4,
          row_count: 22.0,
          row_count_relative: 0.22,
        },
        {
          score: 0.45,
          row_count: 31.0,
          row_count_relative: 0.31,
        },
        {
          score: 0.55,
          row_count: 1.0,
          row_count_relative: 0.1,
        },
        {
          score: 0.5,
          row_count: 13.0,
          row_count_relative: 0.13,
        },
        {
          score: 0.6,
          row_count: 15.0,
          row_count_relative: 0.15,
        },
        {
          score: 0.5,
          row_count: 1.0,
          row_count_relative: 0.1,
        },
        {
          score: 0.6,
          row_count: 8.0,
          row_count_relative: 0.8,
        },
      ],
    },
  ],
}
