/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type TopicSentimentFilter = {
  invert?: boolean;
  value: null;
  type?: TopicSentimentFilter.type;
};
export namespace TopicSentimentFilter {
  export enum type {
    TOPICS = 'topics',
  }
}

