// include this file for UnoCSS build to included classes used in this file
// @unocss-include

import CustomIcon from '@/components/CustomIcon.vue'
import EditableText from '@/components/EditableText.vue'
import FaIcon from '@/components/FaIcon.vue'
import { type HTMLAttributes, h } from 'vue'
import { NButton, NIcon, NTooltip } from 'naive-ui'
import type { ButtonProps, IconProps, TooltipProps } from 'naive-ui'
import type { FontAwesomeIconProps } from '@fortawesome/vue-fontawesome'
import type { IEditableTextProps } from '@/components/EditableText.vue'
import type { TIcon } from '@/plugins/font-awesome'

export function renderButton(content: ReturnType<typeof h>, props?: ButtonProps) {
  return h(NButton, props, {
    default: () => content,
  })
}

// omits icon from the type itself, since the icon attr is taken as first argument in this function
export function renderFaIcon(faIcon: TIcon, props: Omit<FontAwesomeIconProps, 'icon'> & HTMLAttributes = {}) {
  return h(FaIcon, {
    ...props,
    icon: faIcon,
  })
}

type TRenderFaIconTextOptions = {
  textProps?: HTMLAttributes
  faIconProps?: Omit<FontAwesomeIconProps, 'icon'> & HTMLAttributes
  NIconProps?: IconProps
}
export function renderFaIconText(text: string, faIcon: TIcon, options?: TRenderFaIconTextOptions) {
  const mergedTextProps = {
    class: 'ml-2',
    ...options?.textProps,
  }

  return h('div', { class: 'flex items-center' }, [
    h(NIcon, options?.NIconProps, {
      default: () => renderFaIcon(faIcon, options?.faIconProps),
    }),
    h('span', mergedTextProps, text),
  ])
}

type TRenderCustomIconTextOptions = {
  iconProps?: Omit<InstanceType<typeof CustomIcon>['$props'], 'icon'> & HTMLAttributes
  textProps?: HTMLAttributes
}
export function renderCustomIconText(text: string, icon: TCustomIconName, options?: TRenderCustomIconTextOptions) {
  const mergedTextProps = {
    class: 'ml-2',
    ...options?.textProps,
  }
  const mergedIconProps = {
    ...options?.iconProps,
    icon,
  }

  return h('div', { class: 'flex items-center' }, [h(CustomIcon, mergedIconProps), h('span', mergedTextProps, text)])
}

type TRenderIconButtonOptions = {
  iconProps?: Omit<FontAwesomeIconProps, 'icon'> & HTMLAttributes
  buttonProps?: ButtonProps & HTMLAttributes
}

export function renderIconButton(faIcon: TIcon, options?: TRenderIconButtonOptions) {
  const mergedButtonProps = {
    type: 'primary',
    text: true,
    focusable: false,
    class: 'color-neutral-400',
    ...options?.buttonProps,
  }

  return renderButton(renderFaIcon(faIcon, options?.iconProps), mergedButtonProps as ButtonProps)
}

export function renderTooltip(
  trigger: ReturnType<typeof h>,
  content: ReturnType<typeof h> | string,
  props: TooltipProps & HTMLAttributes = {}
) {
  const mergedProps = { style: { maxWidth: '320px' }, ...props }

  return h(NTooltip, mergedProps, {
    trigger: () => trigger,
    default: () => content,
  })
}

export const renderTableTH = (title: string, options = {}) => {
  return h('span', { class: 'mr-1 text-xs font-500 whitespace-break-spaces', ...options }, title)
}

export const renderEditableTableCell = (props: IEditableTextProps, onUpdate: (v: string) => void) => {
  return h<IEditableTextProps>(
    EditableText,
    { ...props, 'onUpdate:value': onUpdate, class: 'max-w-[calc(100%-16px)]' },
    {
      default: () => `${props.value} test` || '-',
    }
  )
}

export const renderSorterIcon = (order: 'ascend' | 'descend' | false) => {
  if (order === 'ascend') return renderFaIcon('fa-arrow-down-wide-short', { size: 'sm', class: 'c-gray-800' })
  return renderFaIcon('fa-arrow-up-wide-short', { size: 'sm', class: 'c-gray-800' })
}

export function renderErrorDialogIcon(icon: TIcon = 'far fa-trash') {
  return h('div', { class: 'flex items-center justify-center pa-2 rounded bg-red-50' }, [
    renderFaIcon(icon, { class: 'c-red text-xs' }),
  ])
}
