/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type NumericalDistributionHistogramConfig = {
  y_axis: NumericalDistributionHistogramConfig.y_axis;
};
export namespace NumericalDistributionHistogramConfig {
  export enum y_axis {
    ABSOLUTE = 'absolute',
    RELATIVE = 'relative',
  }
}

