<template>
  <div
    class="sidebar-container c-transition gap-1"
    :class="{
      collapsed: collapsed,
      hidden: route.meta.noSidebar || secondaryHidden,
    }"
  >
    <slot name="pre" />

    <template v-for="(item, idx) in items" :key="idx">
      <!-- Menu Items -->
      <RouterLink
        v-if="!item.type"
        v-slot="{ navigate, isActive }"
        :to="item.to"
        :custom="item.to === '/logout'"
        :disabled="item.disabled"
      >
        <SidebarMenuItem
          :item="item"
          :collapsed="collapsed"
          :disabled="item.disabled || disabled"
          :is-active="isActive"
          @click="navigate"
        />
      </RouterLink>

      <!-- Custom Items -->
      <template v-else-if="item.type === 'custom'">
        <!-- If provided with a render function, use that -->
        <Component :is="item.component" v-if="item.component" v-bind="item.componentProps" />
        <!-- Otherwise, use the default SidebarMenuItem -->
        <SidebarMenuItem v-else :item="item" :collapsed="collapsed" :disabled="disabled" @click="item.onClick" />
      </template>

      <!-- Menu toggler -->
      <div v-else-if="item.type === 'toggler'" class="text-center">
        <NButton
          block
          class="menu-toggler"
          :class="[item.buttonClass]"
          circle
          size="large"
          :bordered="false"
          :focusable="false"
          @click="handleTogglerClick(!collapsed)"
        >
          <NIcon size="large">
            <FaIcon v-if="collapsed" class="c-gray-500" icon="far fa-circle-chevron-right" />
            <FaIcon v-else class="c-gray-500" icon="far fa-circle-chevron-left" />
          </NIcon>
        </NButton>
      </div>

      <!-- Divider -->
      <NDivider v-else-if="item.type === 'divider'" class="menu-divider bg-gray-400" />
    </template>
  </div>
</template>

<script setup lang="ts">
import SidebarMenuItem from '@/components/common/SidebarMenuItem.vue'
import { type Component, computed, onMounted, ref } from 'vue'
import { type RouteLocationRaw, useRoute } from 'vue-router'
import { useThemeStore } from '@/store'
import type { TIcon } from '@/plugins/font-awesome'

type TSidebarDivider = { type: 'divider' }
type TSidebarToggler = { type: 'toggler'; buttonClass?: string }
type TSidebarCustom = {
  type: 'custom'
  name: string
  icon?: TIcon
  buttonClass?: string
  onClick?: () => void
  component?: Component
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  componentProps?: Record<string, any>
}
type TSidebarRouteItem = {
  type?: undefined
  name: string
  to: RouteLocationRaw
  icon?: TIcon
  buttonClass?: string
  disabled?: boolean
}

export type TSidebarItem = TSidebarDivider | TSidebarToggler | TSidebarRouteItem | TSidebarCustom

const props = withDefaults(
  defineProps<{
    items: Array<TSidebarItem>
    collapsed?: boolean
    secondary?: boolean
    disabled?: boolean
  }>(),
  {
    items: () => [],
    collapsed: false,
    secondary: false,
    disabled: false,
  }
)

const emit = defineEmits<{
  'update:collapsed': [value: boolean]
}>()

const route = useRoute()
const themeStore = useThemeStore()

const secondaryHidden = ref(props.secondary ? true : false)

const styles = computed(() => {
  return {
    borderLeft: !props.secondary ? 'none' : `1px solid ${themeStore.themeVars.dividerColor}`,
    // itemColor: uno.colors.white,
    // itemActiveColor: !props.secondary ? uno.colors.primary[400] : uno.colors.white,
    // bgColor: !props.secondary
    //   ? themeStore.themeVars.bodyColor
    //   : colord(themeStore.themeVars.bodyColor).lighten(0.05).toHex(),
  }
})

const handleTogglerClick = (collapsed: boolean) => {
  // Only set localstorage item if it's explicitly clicked by the user
  localStorage.setItem('sidebarCollapsed', JSON.stringify(collapsed))
  emit('update:collapsed', collapsed)
}

onMounted(() => {
  if (props.secondary) secondaryHidden.value = false
})
</script>

<style lang="scss" scoped>
$sidebarXPadding: 16px;
$sidebarStaticWidth: 74px;
// calculate width with padding on each side
$sidebarWidth: #{$sidebarStaticWidth - $sidebarXPadding * 2};

.sidebar-container {
  width: 200px;
  min-width: 200px;
  padding: 20px $sidebarXPadding;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-left: v-bind('styles.borderLeft');
  transition: all var(--default-transition);

  &.collapsed {
    min-width: $sidebarWidth;
    width: $sidebarWidth;
  }

  &.hidden {
    min-width: 0;
    width: 0;
    padding: 0;
  }

  .menu-divider {
    margin: 16px 0;
  }

  .menu-toggler {
    position: relative;

    :deep(.n-button__content) {
      z-index: 99;
      background-color: var(--body-color);
    }

    &:after {
      position: absolute;
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      right: 0;
      top: 50%;
      border-top: 1px dashed var(--c-gray-400);
    }
  }
}
</style>
